import { createApp } from 'vue'
import router from './router'
import i18n from './locals'

import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'


import App from './App.vue'

const app = createApp(App)

app.use(router)
   .use(i18n)
   .use(ElementPlus)

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
   
   app.component(key, component)
}

app.mount('#app')
