import { createRouter, createWebHistory } from "vue-router";

const Main = () => import('@/pages/Main.vue')
const HomePage = () => import('@/pages/home/HomePage.vue')
const ProductCenter = () => import('@/pages/productCenter/ProductCenter.vue')
const Solution = () => import('@/pages/solution/Solution.vue')
const IndustryCase = () => import('@/pages/industryCase/IndustryCase.vue')

const PCShop = () => import('@/pages/productCenter/shop/PCShop.vue')
const PCWorryFree = () => import('@/pages/productCenter/worryFree/PCWorryFree.vue')
const PCMiddleGround = () => import('@/pages/productCenter/middleGround/PCMiddleGround.vue')
const PCHardware = () => import('@/pages/productCenter/hardware/PCHardware.vue')

const SRetail = () => import('@/pages/solution/retail/SRetail.vue')
const SShop = () => import('@/pages/solution/shop/SShop.vue')
const MMiddleGround = () => import('@/pages/solution/middleGround/MMiddleGround.vue')
const AboutUs = () => import('@/pages/aboutUs/AboutUs.vue')

const MobileApply = () => import('@/pages/mobile/apply/MobileApply.vue')

const router = createRouter({
    history: createWebHistory(),
    routes: [
        {
            path: '/',
            redirect: '/main/homePage'
        },
        {
            path: '/main/',
            name: 'main',
            component: Main,
            children:[
                {
                    path: '',
                    redirect: '/main/homePage'
                },
                {
                    path: 'homePage',
                    name: 'pomePage',
                    component: HomePage,
                },
                {
                    path: 'productCenter',
                    name: 'productCenter',
                    component: ProductCenter,
                    children:[
                        {
                            path: '',
                            redirect: '/main/productCenter/shop'
                            // component: HomePage,
                        },
                        {
                            path: 'shop',
                            component: PCShop,
                        },
                        {
                            path: 'worryFree',
                            component: PCWorryFree,
                        },
                        {
                            path: 'middleGround',
                            component: PCMiddleGround,
                        },
                        {
                            path: 'hardware',
                            component: PCHardware,
                        },
                    ]
                },
                {
                    path: 'solution',
                    name: 'solution',
                    component: Solution,
                    children:[
                        {
                            path: '',
                            redirect: '/main/solution/retail'
                            // component: HomePage,
                        },
                        {
                            path: 'retail',
                            component: SRetail,
                        },
                        {
                            path: '/main/solution/shop',
                            component: SShop,
                            children:[
                                {
                                    path: '',
                                    redirect: '/main/solution/shop/put'
                                    // component: HomePage,
                                },
                                {
                                    path: 'put',
                                    name: 'put',
                                    component: () => import('@/pages/solution/shop/SPut.vue'),
                                },
                                {
                                    path: 'passengerFlow',
                                    name: 'passengerFlow',
                                    component: () => import('@/pages/solution/shop/SPassengerFlow.vue'),
                                },
                                {
                                    path: 'securityInspection',
                                    name: 'securityInspection',
                                    component: () => import('@/pages/solution/shop/SSecurityInspection.vue'),
                                },
                                {
                                    path: 'interactionDisplay',
                                    name: 'interactionDisplay',
                                    component: () => import('@/pages/solution/shop/SInteractionDisplay.vue'),
                                },
                                {
                                    path: 'matchingRecommend',
                                    name: 'matchingRecommend',
                                    component: () => import('@/pages/solution/shop/SMatchingRecommend.vue'),
                                },
                                {
                                    path: 'guide',
                                    name: 'guide',
                                    component: () => import('@/pages/solution/shop/SGuild.vue'),
                                },
                                {
                                    path: 'member',
                                    name: 'member',
                                    component: () => import('@/pages/solution/shop/SMember.vue'),
                                },
                                {
                                    path: 'cashier',
                                    name: 'cashier',
                                    component:  () => import('@/pages/solution/shop/SCashier.vue'),
                                },
                                {
                                    path: 'network',
                                    name: 'network',
                                    component: () => import('@/pages/solution/shop/SNetwork.vue'),
                                },
                            ]
                        },
                        {
                            path: 'middleGround',
                            component: MMiddleGround,
                            children:[
                                {
                                    path: '',
                                    redirect: '/main/solution/middleGround/purchase'
                                    // component: HomePage,
                                },
                                {
                                    path: 'purchase',
                                    component: MMiddleGround,
                                },
                                {
                                    path: 'stock',
                                    component: MMiddleGround,
                                },
                                {
                                    path: 'distribution',
                                    component: MMiddleGround,
                                },
                                {
                                    path: 'operate',
                                    component: MMiddleGround,
                                },
                                {
                                    path: 'performance',
                                    component: MMiddleGround,
                                },
                                {
                                    path: 'shopAssistant',
                                    component: MMiddleGround,
                                },
                                {
                                    path: 'purchaseSaleInventory',
                                    component: MMiddleGround,
                                },
                                {
                                    path: 'privateSphere',
                                    component: MMiddleGround,
                                },
                                {
                                    path: 'publicSphere',
                                    component: MMiddleGround,
                                },
                                {
                                    path: 'finance',
                                    component: MMiddleGround,
                                },
                            ]
                        },
                    ]
                },
                {
                    path: 'industryCase',
                    name: 'industryCase',
                    component: IndustryCase,
                },
                {
                    path: 'aboutUs',
                    name: 'aboutUs',
                    component: AboutUs,
                },
            ]
        },
        {
            path: '/mobile/apply',
            component: MobileApply,
        }
    ],
})


export default router