import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import { onMounted } from 'vue';
import { RouterView } from 'vue-router';
export default {
  __name: 'App',
  setup(__props) {
    onMounted(() => {
      document.body.style.setProperty('--el-color-primary', '#018EFE');
    });
    return (_ctx, _cache) => {
      return _openBlock(), _createBlock(_unref(RouterView));
    };
  }
};